/* Classes in this file are available in any component
- global basic styles
- reset styles
*/

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ReactModal__Overlay {
  z-index: 101;
}

.login-form {
  display: inline-block;
  width: 20em;
}

.full-width-input {
  display: block;
  text-align: right;
}

.full-width-input input {
  width: 12em;
  padding: 4px 8px;
  margin: 4px 8px;
  border: 1px solid #aaaaaa;
  border-radius: 0;
}


.button-basic, .button-basic-sm{
  border: none;
  font-family: 'Montserrat';
  font-size: 12px;
  background-color: #81BCBF;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  padding: 15px 50px;
  box-sizing: border-box;
  box-shadow: 0px 20px 50px #0000004D;
  border-radius: 40px;
  margin: 25px auto;
  text-transform: uppercase;
}

.button-basic:hover {
  cursor: pointer;
  background-color: #73a9ac;
}

.air-datepicker {
  z-index: 102 !important;/* 1 higher than modal z-index*/
}

