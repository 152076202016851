/* General CSS normalisation - investigating common CSS seen in emails */

/*
Normalise on all email clients
Apple Mail, iOS Mail plus many more have preset margin and padding for the email body - this normalises it so rendering is consistent and designers can choose.
*/

body {
  margin: 0;
  padding: 0;
  }

/*
Fix for Outlook on Windows
border-collapse to stop spaces between tables caused by border size
mso-table-lspace / mso-table-rspace to ensure no left and right space is added next to tables - Outlook specific CSS attributes
 */

table {
  border-collapse:collapse;
  mso-table-lspace:0;
  mso-table-rspace:0;
  }

/*
Older versions of Samsung mail reset the font-size on <h1>-<h6> elements - But the newer versions don’t.
Mail.ru resets font-size on <h1> & <h3> but other <h*> are left
outlook.com resets margin on an <h3> but others are left
So I think a “normalise” on <h1>-<h3> would make sense
*/

h1 {
  margin:0.67em 0;
  font-size:2em;
  }
h2 {
  margin:0.83em 0;
  font-size:1.5em;
  }

/* html[dir] h3 is to increase specificity to override Outlook.com */
html[dir] h3, h3 {
  margin:1em 0;
  font-size:1.17em;
  }

/* From here - all CSS normalisation is based on a specific email client sitution */

/* Fix for Outlook links color fix for links and visited links */

span.MsoHyperlink {
  color: inherit !important;
  mso-style-priority: 99 !important;
  }

span.MsoHyperlinkFollowed {
  color: inherit !important;
  mso-style-priority: 99 !important;
      }

/* normalise link attributes in Apple Mail / iOS Mail apps - to match the parent element */

#root [x-apple-data-detectors=true],
a[x-apple-data-detectors=true]{
	color: inherit !important;
	text-decoration: inherit !important;
}

/* normalise link attributes in Gmail - to match the parent element. NOTE: Need to add class="body" to the body element and a DOCTYPE must be present. */

u + .body a {
  color: inherit;
  text-decoration: none;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  }

/* Mark Robbins found iOS Gmail will add word-spacing: 1px and word-wrap: break-word
https://github.com/JayOram/email-css-resets/issues/2#issue-805476023
so added the below to fix that

This doesn't fix GANGA - so may need to be added inline -
<body style="word-wrap: normal; word-spacing:normal;">
*/

.body {
   word-wrap: normal;
   word-spacing:normal;
  }

/* centre email on Android 4.4 - margin reset */

div[style*="margin: 16px 0"] {
margin: 0!important;
}

.email-wrapper {
  width: 100%;
  font-family: 'Arial', sans-serif;
  font-size: 12px;
  color: #707070;
}

.email-content {
  margin: auto;
  background: #fff;
  max-width: 600px;
  border: 1px solid #eee;
  line-height: 1.5;
  box-sizing: border-box;
}

.invite-content {
  background: #f1efed;
  padding: 40px;
}

.email-body {
  padding: 40px;
}

.bold-indent {
  font-weight: bold;
  padding-left: 40px;
}

.invite-body {
  border-bottom: 1px solid #707070;
  padding-bottom: 40px !important;
  margin-bottom: 40px;
}

.button {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  background-color: #81bcbf;
  padding: 8px 30px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 20px 0;
}

.button:hover {
  background-color: #70a4a7;
}

.top-image {
  margin-bottom: 30px;
}

.top-image-placeholder {
  width: 240px;
  height: 90px;
  border: 1px solid #707070;
  margin-bottom: 30px;
}

.email-body-text p {
  margin-bottom: 15px;
  text-align: left;
}

.email-footer {
  background: #f1efed;
  padding: 20px 40px;
  font-size: 9px;
}

.footer-left {
  width: 160px;
  margin:0 30px 50px 0;
  float: left;
}

.footer-left span {
  text-transform: uppercase;
  display: block;
  font-size: 8px;
  margin: 15px 0;
  font-weight: bold;
}

.invite-content .email-body, .invite-content .email-footer {
  padding: 0;
}